import { React, useState } from "react";
import Logo1 from "../img/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faXmark, faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons"
import {faFacebook, faFacebookF, faGoogle, faInstagram, faYelp } from "@fortawesome/free-brands-svg-icons";

function Navbar(props) {
    const [Click, setClick] = useState(false);
    const [Scroll, SetScroll] = useState(false);

    const Change_color = () => {
        if (window.scrollY >= 10) {
            SetScroll(true);
        }
        else { SetScroll(false); }
    };
    window.addEventListener('scroll', Change_color);

    const Icon_Style = { fontSize: '22', };

    return (
        <div className="">
            <nav className={Scroll ? "bg-black/90 shadow-lg px-4 transition py-4 ease-out duration-200  delay-100 flex  justify-center lg:justify-around w-full fixed top-0 left-0 right-0 z-10 " :
                "flex text-black justify-center lg:justify-around bg-none  w-screen fixed px-4 top-0 left-0 right-0 z-10"}>
                <div className="flex items-center cursor-pointer group">
                    <img src={Logo1} alt="" className= {Scroll? " w-32 md:w-44 group-hover:opacity-70": "w-32 md:w-44 group-hover:opacity-70"} />
                </div>
                <div className={Scroll ? "lg:flex text-md hidden items-center space-x-20 text-xl capitalize text-white" :
                    "lg:flex text-md hidden items-center space-x-20 text-xl capitalize text-white"}>
                    <a onClick={() => props.handleTabClick("Home")} className={`${props.isActive("Home")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/">
                        Trang Chủ
                    </a>
                    <span onClick={() => props.handleTabClick("Services")} className={`${props.isActive("Services")} flex cursor-pointer hover:text-main-color transition ease-in-out delay-150 duration-200`}>
                        Dịch Vụ
                    </span>
                    <span onClick={() => props.handleTabClick("Contact")} className={`${props.isActive("Contact")} flex cursor-pointer hover:text-main-color transition ease-in-out delay-150 duration-200`} >
                        Liên Hệ
                    </span>
                </div>
        
                {/* <div className={Scroll ? "lg:hidden text-3xl cursor-pointer flex items-center space-x-4" :
                    "lg:hidden space-x-4 text-3xl cursor-pointer flex items-center"}>
                    <div onClick={() => setClick(!Click)} className={Scroll ? "lg:hidden transition text-3xl cursor-pointer flex items-center text-main-color space-x-4" : "lg:hidden space-x-4 blkock text-3xl cursor-pointer flex items-center text-main-color"}>
                        {!Click ?
                            <FontAwesomeIcon className=" text-main2" icon={faBarsStaggered} /> :
                            <FontAwesomeIcon className=" text-main2" icon={faXmark} />
                        }
                    </div>
                </div> */}

                <div className={Scroll ? "hidden lg:flex items-center space-x-12 text-white" :
                    "hidden lg:flex items-center  space-x-12 text-white"}>

                    <a className="cursor-pointer flex" target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/sene3cypBuxjyD4b9">
                        <FontAwesomeIcon className="hover:text-green-300" style={Icon_Style} icon={faGoogle}></FontAwesomeIcon>
                    </a>
                    <a className="cursor-pointer flex" target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61566758863531">
                        <FontAwesomeIcon className="hover:text-sky-400 transition ease-in-out delay-150 duration-200" style={Icon_Style} icon={faFacebookF}></FontAwesomeIcon>
                    </a>
                </div>
                
            </nav>
            {/* {Click ?
                <nav className="overflow-hidden space-y-12 md:space-y-24 top-20 md:top-28 fixed p-6 w-screen h-screen bg-white transition duration-700 ease-out lg:hidden left-0 right-0 z-20  mx-auto  text-black">
                    <div className=" space-y-16 md:space-y-24 flex uppercase flex-col text-lg mt-12">
                        <a onClick={() => setClick(!Click)} className="" href="/">Home</a>
                        <a onClick={() => setClick(!Click)} className="" href="/ #Services">Services</a>
                        <a onClick={() => setClick(!Click)} className="" href="/#Contact">Contact</a>
                        <button
                            className="hover:bg-black group  transition ease-out duration-200 cursor-pointer px-4 py-3 border-black border-2 capitalize tracking-wide">
                            <a target="_blank" href="tel:5302372893" className="group-hover:text-black">Contact Us</a>
                        </button>
                    </div>
                </nav>
                : null
            } */}
        </div>
    );
};


export default Navbar;