import React from "react";
import { RxDoubleArrowDown } from "react-icons/rx";
import { Slide, Fade, JackInTheBox } from "react-awesome-reveal";

import img1 from "../img/img1.png";
import img2 from "../img/bg3.png";
import img3 from "../img/bgtest.png";


import ser1 from "../img/1.png";
import ser2 from "../img/2.png";
import ser3 from "../img/3.png";
import ser4 from "../img/4.png";
import ser5 from "../img/5.png";


export default function Home(){

    function scrollSec(){
        const home1 = document.getElementById('About');
        home1?.scrollIntoView({
            behavior:"smooth"
        });
    };

    const Data1 = [
        {
            id: 1,
            name: 'Triệt Lông Vĩnh Viễn',
            img: ser1,
            desc: 'Dịch vụ triệt lông vĩnh viễn tại Kim Ngân Spa giúp loại bỏ lông không mong muốn hiệu quả và an toàn, mang đến làn da mịn màng, sạch sẽ mà không gây đau đớn.'
        },
        {
            id: 2,
            name: 'Phun Xăm Thẩm Mỹ',
            img: ser2,
            desc: 'Phun xăm thẩm mỹ tại Kim Ngân Spa là giải pháp hoàn hảo để làm đẹp cho đôi chân mày và đôi môi, mang đến sự sắc nét và tự nhiên, phù hợp với từng gương mặt.'
        },
        {
            id: 3,
            name: 'Uốn Mi - Sơn Gel',
            img: ser3,
            desc: 'Dịch vụ uốn mi và sơn gel tại Kim Ngân Spa mang lại đôi mắt quyến rũ và bộ móng tay đẹp hoàn hảo, giúp bạn tự tin và nổi bật trong mọi dịp.'
        },
        {
            id: 4,
            name: 'Chăm Sóc Chuyên Sâu',
            img: ser4,
            desc: 'Chăm sóc da chuyên sâu tại Kim Ngân Spa giúp cải thiện làn da từ sâu bên trong, mang lại làn da sáng mịn, đều màu và tràn đầy sức sống.'
        },
        {
            id: 5,
            name: 'Tắm Trắng Body',
            img: ser5,
            desc: 'Dịch vụ tắm trắng body tại Kim Ngân Spa giúp làm sáng da toàn thân, mang lại làn da rạng rỡ, tươi trẻ với liệu trình an toàn và hiệu quả.'
        }
    ];

	const Data2 = [
        {
            id: 1,
            reason: "Chất Lượng Dịch Vụ Đỉnh Cao",
            desc: "Chúng tôi cam kết sử dụng các công nghệ hiện đại và sản phẩm chất lượng cao để mang lại kết quả tốt nhất cho khách hàng, từ triệt lông vĩnh viễn, phun xăm thẩm mỹ đến chăm sóc da chuyên sâu."
        },
        {
            id: 2,
            reason: "Đội Ngũ Chuyên Gia Kinh Nghiệm",
            desc: "Với đội ngũ chuyên viên giàu kinh nghiệm và được đào tạo chuyên sâu, chúng tôi luôn đảm bảo bạn sẽ nhận được sự chăm sóc chuyên nghiệp và tư vấn tận tình nhất."
        },
        {
            id: 3,
            reason: "Không Gian Thư Giãn, Sang Trọng",
            desc: "Không gian tại Kim Ngân Spa được thiết kế hiện đại, sang trọng nhưng ấm cúng, mang lại cảm giác thư giãn tuyệt đối mỗi khi bạn trải nghiệm dịch vụ."
        },
        {
            id: 4,
            reason: "Dịch Vụ Đa Dạng",
            desc: "Tại Kim Ngân Spa, chúng tôi cung cấp nhiều dịch vụ làm đẹp từ tắm trắng body, nối mi - sơn gel, đến chăm sóc da toàn diện, giúp bạn dễ dàng lựa chọn gói dịch vụ phù hợp với nhu cầu của mình."
        },
        {
            id: 5,
            reason: "Kết Quả Hiệu Quả, An Toàn",
            desc: "Sức khỏe và vẻ đẹp của bạn là ưu tiên hàng đầu. Chúng tôi đảm bảo mọi liệu trình đều an toàn, mang lại hiệu quả cao mà không gây hại cho làn da và cơ thể."
        }
    ];


    return (

        <main>

            <section className=" w-screen relative h-screen bg-bg3 lg:bg-bg1 bg-center lg:bg-cover bg-no-repeat">
                <div className=" flex flex-col items-center w-screen h-screen bg-black bg-opacity-20 justify-between">
                    <div></div>
                    <div></div>
					<div className=" lg:hidden block -mt-24">
						<img src={img3} className="" />
					</div>
                    <div className="p-4 flex">
                        <button onClick={() => scrollSec()} className="group text-white">
                            <RxDoubleArrowDown className="ease-in-out delay-100 duration-150 group-hover:-translate-y-2 group-hover:text-main-color text-5xl" />
                        </button>
                    </div>
                </div>
            </section>

            <section id="About" className=" bg-black">
                <div className="pb-6 pt-6 lg:pt-24 lg:pb-24">
                    <div className="lg:mx-5">
                        <div className="grid cursor-pointer grid-cols-1 lg:gap-0 gap-5 lg:grid-cols-2">
                            <JackInTheBox>
                                <div className="lg:h-[600px] h-[320px] lg:p-4 p-2 lg:rounded-lg md:block overflow-hidden">
                                    <img className="ease-in-out h-full lg:rounded-lg transition-all duration-1000 hover:scale-125" src={img1}/>
                                </div>
                            </JackInTheBox>
                            <div className="space-y-2 px-4 lg:px-12 lg:space-y-8 lg:py-4 justify-center flex flex-col items-center text-center">
                                <div className=" text-[30px] flex flex-col items-center lg:text-3xl tracking-wide space-y-2">
                                    <div className=" border-[1px] border-main-color w-16"></div>
                                    <h2 className=" text-main-color font-Cin">
                                        Trải Nghiệm Tại Kim Ngân Spa
                                    </h2>
                                </div>
                                <div className=" flex flex-col md:text-lg text-white space-y-6 lg:space-y-8">
                                    <div className="space-y-4 flex flex-col">
                                        <span className="">
                                            Chào mừng bạn đến với Kim Ngân Spa, spa uy tín hàng đầu tại Tuy Hòa nằm ở 237 Bà Triệu, Phường 7, chuyên cung cấp các dịch vụ làm đẹp hiện đại và an toàn. Chúng tôi mang đến cho khách hàng một loạt các dịch vụ chăm sóc da như điều trị mụn, tàn nhang, nám da không bong tróc, căng bóng xóa nhăn trẻ hóa, và tái sinh da tầng.                                        </span>
                                        <span>
                                            Ngoài ra, Kim Ngân Spa còn nổi bật với các dịch vụ phun xăm thẩm mỹ, triệt lông vĩnh viễn, tắm trắng body, và chăm sóc nail - sơn Gel chuyên nghiệp. Đội ngũ chuyên gia tại spa cam kết mang đến dịch vụ nối mi - uốn mi tinh tế, an toàn và hiệu quả cao, giúp khách hàng tự tin với vẻ ngoài hoàn hảo.
                                        </span>
                                        <span>
                                            Với sự kết hợp giữa kỹ thuật hiện đại và liệu trình chuyên sâu, Kim Ngân Spa Tuy Hòa tự hào là địa chỉ làm đẹp được nhiều khách hàng tin tưởng. Hãy trải nghiệm ngay các dịch vụ cao cấp và sự chăm sóc tận tình tại Kim Ngân Spa.
                                        </span>
                                    </div>
                                    <div className=" hidden lg:block">
										<button className = " ease-in-out delay-100 duration-150 hover:bg-opacity-80 hover:-translate-y-2 px-4 rounded-xl py-3 bg-main-color group" onClick={() => scrollSec()}>
											<span className=" text-xl group-hover:text-white">
                                                Liên Hệ Chúng Tôi
                                            </span>
										</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

            <section id="Services" className="">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-4 lg:space-y-12 max-w-screen-xl">
						<div className=" flex items-center space-y-5 lg:space-y-8 flex-col">
                            <div className=" text-[30px] lg:text-3xl tracking-wide space-y-2">
                                <div className=" border-[1px] border-main-color w-16"></div>
                                <h2 className=" text-main-color font-Cin">
                                    Sẵn Sàng Cùng Kim Ngân Spa?                               
                                </h2>
                            </div>
                            <div className=" text-white lg:text-center flex justify-center">
                                <div className=" lg:w-3/4">
                                    <span className=" text-lg">
                                        Kim Ngân Spa mang đến trải nghiệm làm đẹp đẳng cấp như điều trị mụn, trẻ hóa da, tắm trắng, triệt lông vĩnh viễn, nối mi, 
                                        phun xăm thẩm mỹ, và nail - sơn Gel, giúp bạn hoàn thiện vẻ đẹp từ đầu đến chân.
                                    </span>
                                </div>
                            </div>
                            <div className="grid md:grid-cols-3 lg:mx-4 justify-items-center gap-2 lg:gap-5 cursor-pointer">
                                {Data1.map((s) => (
									<div key={s.id} className="shadow-md rounded-lg space-y-2 mb-2 py-4 ease-in-out transition-all duration-1000 lg:hover:scale-105">
										<div className=" lg:p-4">
											<img className="h-full w-full" src={s.img} />
										</div>
										<div className=" text-white lg:p-4 space-y-2">
											<div>
												<h1 className="text-main-color text-xl lg:text-2xl text-center">{s.name}</h1>
											</div>
											<hr></hr>
											{
												s.id === 5 ? (
													<div className=" mt-4 flex flex-col space-y-2 text-center">
														<span className="lg:text-lg">{s.desc}</span>
													</div>
												): (
													<div className=" mt-4 text-center">
														{s.desc2 !== ''? (
															<span className="lg:text-md">{s.desc}</span>

														): (
															<span className="lg:text-md">{s.desc}</span>
														)}
													</div>
												)
											}
										</div>
									</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			
			<section id="" className=" bg-black">
                <div className="pb-6 pt-6 lg:pt-12 lg:pb-24">
                    <div className="lg:mx-5">
                        <div className="grid cursor-pointer grid-cols-1 lg:gap-0 gap-5 lg:grid-cols-2">
                            <JackInTheBox>
                                <div className="lg:h-[630px] h-[320px] lg:p-4 p-2 lg:rounded-lg md:block overflow-hidden">
                                    <img className="ease-in-out h-full lg:rounded-lg transition-all duration-1000 hover:scale-125" src={img2}/>
                                </div>
                            </JackInTheBox>
                            <div className="space-y-2 text-white px-4 lg:px-12 lg:space-y-4 lg:py-4 justify-center flex flex-col">
                                <div className=" text-[30px] flex flex-col lg:text-3xl tracking-wide space-y-2">
                                    <div className=" border-[1px] border-main-color w-16"></div>
                                    <h2 className=" text-main-color font-Cin">
										Tại Sao Chọn Kim Ngân Spa?
                                    </h2>
                                </div>
                                {Data2.map((s) => (
									<div className=" lg:mx-2 flex flex-col" key={s.id}>
										<div className=" lg:mx-2 font-bold italic text-xl">
											<h1>{s.id}) {s.reason}</h1>
										</div>
										<div className="lg:mx-4 font-extralight">
											<div className=" lg:w-3/4">
												<span className="">{s.desc}</span>
											</div>
										</div>
									</div>
								))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

			<section id="Contact" className=" bg-black text-white">
                <div className="pt-6 lg:pt-12 h-full pb-6 space-y-4">
                    <div className="lg:p-4 h-full p-2">
                        <div className=" grid h-full lg:grid-cols-2 gap-8 lg:gap-12">
                            <div className="">
                                <iframe className=" w-full h-[500px] lg:h-full" id="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.0606366181773!2d109.31125697588841!3d13.095343612151574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x316fec4724d66e05%3A0x6a18470d7f488414!2zMjM3IEJhzIAgVHJpw6rMo3UsIFBoxrDhu51uZzcsIFR1eSBIw7JhLCBQaMO6IFnDqm4sIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1728899043495!5m2!1sen!2s" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                            </div>
                            <Slide delay={4} direction="down">
                                <div className=" lg:py-24 mx-2 lg:mx-0 flex flex-col space-y-4">
                                    <div className=" text-[30px] lg:text-[44px] flex flex-col tracking-wide space-y-[1px] font-bold">
                                        <div className=" border-[1px] border-main-color w-16"></div>
                                        <h2 className=" font-Playfair text-main-color">
											Liên Lạc Kim Ngân Spa
                                        </h2>
                                    </div>
                                    <div className=" max-w-2xl flex flex-col space-y-5 lg:space-y-6">
                                        <div>
                                            <span>
												Nằm ngay trung tâm thành phố Tuy Hòa, Kim Ngân Spa mang đến một không gian yên tĩnh và thoải mái với các phòng riêng biệt, tạo nên trải nghiệm thư giãn tuyệt đối. 
												ội ngũ nhân viên lành nghề người Việt của chúng tôi luôn tận tâm chăm sóc từng khách hàng, giúp bạn giải tỏa căng thẳng và tái tạo năng lượng. Chúng tôi hân hạnh phục 
												vụ các khu vực tại Tuy Hòa và các khu vực lân cận, mang đến dịch vụ làm đẹp chất lượng cao cho cộng đồng.                                            
											</span>
                                        </div>
                                        <div>
                                            <ul className="text-xl space-y-1">
                                                <li className=" text-main-color lg:text-2xl">Kim Ngân Spa</li>
                                                <li>237 Bà Triệu, Phường7</li>
												<li>Tuy Hòa, Phú Yên, Vietnam</li>
                                            </ul>
                                        </div>
                                        <div className="flex font-bold space-y-2 flex-col lg:text-xl">
                                            <span>
                                                Số Điện Thoại: 0829386944 
                                            </span>
                                        </div>
                                        <div className="">
                                        <button onClick={() => {
                                            window.location.href = "tel:0829386944";
                                        }} className=" mt-4 hover:shadow-lg  flex items-center justify-center space-x-2 bg-main-color text-white border-2 border-main-color hover:bg-main-color ease-in-out delay-100 duration-150 md:text-lg w-[200px] text-md py-3 px-4 group cursor-pointer md:tracking-wide">
                                                <span>Liên Hệ Chúng Tôi</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
            </section>
			
        </main>
    )
}