import './App.css';
import React , {useState , useEffect} from 'react';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import { Fade } from 'react-awesome-reveal'
;
import Navbar from './Components/Navbar';

import Home from './Views/Home';
// import SocialTree from './Views/Review';


// import FloatBtn from "./Components/FloatBtn";
import Footer from './Components/Footer';

function App() {

	const [activeTab, setActiveTab] = useState("Home");

	const checkTree = useLocation().pathname.includes('/Review');


	function scrollSec(el){
        const home1 = document.getElementById(el);
        home1?.scrollIntoView({
            behavior:"smooth"
        });
    };

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
		scrollSec(tabName);
	};

	const isActive = (tabName) => {
		return activeTab === tabName ? " text-main-color border-t-main-color border-t-2 p-2" : "";
	};

	const location = useLocation();

	useEffect(() => {


    switch (activeTab) {
		case "Contact":
			setActiveTab("Contact");
			break;

		case "Services":
			setActiveTab("Services");
			break;

		default:
			setActiveTab("Home");
			break;

		}
		
	}, [location]);

	return (

		<main className=' bg-black font-Roboto overflow-x-hidden'>

			<Navbar activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />
			<Fade>
				<Routes>
				<Route path = "/" element = {<Home activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
				<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</Fade>
			<Footer />
			
		</main>

	);

}

export default App;